import * as React from "react"

const AboutWhy = () => {
  return (
    <div className="about-why">
      <p>
        Podnikání a investování mě baví. Na burze obchoduji od roku 2008. Podníkání, startupy dělám od roku 2013. 
        Zkušenosti sbírám v Evropě a v Sillicon Valley. 
        Od té doby jsem se hodně naučil, vydělal dost peněz a snad i zmoudžel.

        Když se bavím s kamarády, tak jsem sám překvapen, kolik toho běžní lidi neví. Tento blog píši pro ně,
        ale i pro sebe, protože některé věci si chci ještě lépe zpracovat a mít možnost se k nim vracet.
      </p>
    </div>
  )
}

export default AboutWhy
