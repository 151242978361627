import * as React from "react"
import { Link } from "gatsby"
import * as styles from "./header.module.css"

const Header = ({ location, title }) => {
    const rootPath = `${__PATH_PREFIX__}/`
    const isRootPath = location.pathname === rootPath
    let headerLogo

    if (isRootPath) {
        headerLogo = (
            <h1 className="main-heading">
                <Link to="/">{title}</Link>
            </h1>
        )
    } else {
        headerLogo = (
            <Link className="header-link-home" to="/">
                {title}
            </Link>
        )
    }

    return (
        <header className="global-header">
            <div id="logo-wrapper">
                { headerLogo }
            </div>
            <nav className={styles.headerNav}>
                <ul>
                    <li><Link to="/about">O mně</Link></li>
                </ul>
            </nav>
        </header>
    )
}

export default Header
