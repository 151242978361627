import * as React from "react"
import Header from "./header"
import { Link } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div>

      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <Header location={location} title={title} />
        <main>{children}</main>
      </div>

      <div className="footer-wrapper">
        <div className="global-wrapper">
          <footer>
            <div>
              © {new Date().getFullYear()}, Jakub Černý, Ph.D.
            </div>
            <div>
              <Link to="/about">Kontakt</Link>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Layout
